




































































































import dayjs from 'dayjs'
import { Component, Vue, Watch } from 'vue-property-decorator'
import { Validations } from 'vuelidate-property-decorators'
import { validationMixin } from 'vuelidate'
import { required, email, minLength } from 'vuelidate/lib/validators'
import controller from '@/app/ui/controllers/UserManagementController'
import Button from '@/app/ui/components/Button/index.vue'
import Modal from '@/app/ui/components/Modal/index.vue'
import DropdownSelect from '@/app/ui/components/DropdownSelect/index.vue'
import LoadingOverlay from '@/app/ui/components/LoadingOverlay/index.vue'
import RoleToggle from '../components/RoleToggle/index.vue'
import EditIcon from '@/app/ui/assets/edit_icon.vue'
import { AccessMenu, Account } from '@/domain/entities/Account'
import { EventBus, EventBusConstants, Utils } from '@/app/infrastructures/misc'

@Component({
  mixins: [validationMixin],
  components: {
    Button,
    EditIcon,
    DropdownSelect,
    RoleToggle,
    LoadingOverlay,
    Modal,
  },
})
export default class EditUserManagement extends Vue {
  controller = controller
  successModal = false
  confirmationModal = false
  form = {
    email: '',
    role: {} as Record<string, unknown>,
    accessMenu: [] as number[],
  }

  created(): void {
    this.fetchUserDetail()

    EventBus.$on(EventBusConstants.UPDATE_ACCOUNT_SUCCESS, () => {
      this.successModal = true
    })
  }

  updated(): void {
    if (
      controller.accessMenuData.length > 0 &&
      controller.roleData.length > 0 &&
      this.form.email === '' &&
      !controller.isLoading
    ) {
      controller.getUser(this.$route.params.id)
    }
  }

  @Watch('userDetail')
  onProgramDetailChanged(val: Account): void {
    this.form = {
      email: val.email || '',
      role: this.roles.find(item => item.value === val.role) || {},
      accessMenu:
        controller.accessMenuData
          .filter(item => !!val.accessMenus?.find(m => m.id === item.id))
          .map(item => item.id as number) || [],
    }
  }

  @Validations()
  validations(): Record<string, unknown> {
    return {
      form: {
        email: { required, email },
        role: { required },
        accessMenu: { required, minLength: minLength(1) },
      },
    }
  }

  get userDetail(): Account {
    return controller.userDetail
  }

  get roles(): Record<string, unknown>[] {
    return this.controller.roleData.map(item => ({
      label: Utils.roleName(item.role as string),
      value: item.role,
    }))
  }

  get userMenuList(): Record<string, unknown>[] {
    const groups: string[] = []
    for (let i = 0; i < controller.accessMenuData.length; i++) {
      if (!groups.includes(`${controller.accessMenuData[i].group}`)) {
        groups.push(`${controller.accessMenuData[i].group}`)
      }
    }

    const menuList = groups.map(item =>
      Object.assign(
        {},
        {
          group: item,
          data: controller.accessMenuData
            .filter(m => m.group === item)
            .map(menu => {
              return new AccessMenu(
                menu.id,
                menu.slug,
                menu.name,
                menu.group,
                !!(
                  controller.userDetail.accessMenus || [new AccessMenu()]
                ).find(v => v.slug === menu.slug)
              )
            }),
        }
      )
    )

    return menuList
  }

  private onToggled(event: { checked: boolean; value: number }) {
    if (event.checked) {
      this.form.accessMenu.push(event.value)
    } else {
      this.form.accessMenu = this.form.accessMenu.filter(
        item => item !== event.value
      )
    }
  }

  private onUpdateAccount() {
    this.confirmationModal = false
    if (!this.$v.form.$invalid) {
      this.controller.updateAccount({
        accountId: this.$route.params.id,
        role: this.form.role.value as string,
        accessMenu: this.form.accessMenu,
      })
    } else {
      Vue.notify({
        title: 'Update Account Invalid',
        text: 'Please check every invalid form',
        type: 'error',
        duration: 5000,
      })
    }
  }

  private fetchUserDetail() {
    controller.getRoles()
    controller.getMenus()
    // Load those two above then load user detail
    // By the logic in updated()
  }

  private onCloseSuccessModal() {
    this.successModal = false
    this.$router.push({
      name: 'UserManagementDetailPage',
      params: { id: this.$route.params.id },
    })
  }

  private formatDate(date: string) {
    return dayjs(date).format('DD MMMM YYYY[\n]HH:mm Z')
  }

  @Watch('controller.accessMenuData')
  private syncMenu() {
    this.controller.syncMenu()
  }

  beforeDestroy(): void {
    EventBus.$off(EventBusConstants.UPDATE_ACCOUNT_SUCCESS)
  }
}
